import React, { useState, useEffect } from "react";
import './Header.less';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { relianItems, anyeItems, qianjiItems, yihuoItems, liehuangItems } from  '../../pages/Data';
import logoImg from "../../assets/common/logo.png";
import iconMenu from "../../assets/common/icon_menu.png";
import iconClose from '../../assets/common/icon_close.png';


export const Header: React.FC = () => {

    const [isShowOver, setIsShowOver] = useState(false);
    const handleShowMenu = (event: any) => {
         event.stopPropagation();
        setIsShowOver(true);
    };

    const handleHideMenu = (event: any) => {
         event.stopPropagation();
        setIsShowOver(false);
    };



    const [inputValue, setInputValue] = useState<string>('先锋潮流艺术珠宝');
    // 路由相关
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // 在组件加载时以及每次路由变化时执行的逻辑
        const hash = window.location.hash;
        if (!hash) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement> ) => {

       //console.log(e.target.value);
       const value = e.target.value;
       setInputValue(value.trim());
      
    }

    const handleSearchClick = () => {
       if(inputValue) {

          if('首页'.includes(inputValue)) {
             navigate('/');
             return;
          }
          if('先锋潮流艺术珠宝'.includes(inputValue)) {
             navigate('/brand');
             return;
          }
          
           const yihuoStr: string = yihuoItems.map(item => item.name).join(', ') + ' ,彝火生花' ;
          if(yihuoStr.includes(inputValue)) {
             navigate('/seriesYihuo');
             return;
          }

          const anyeStr: string = anyeItems.map(item => item.name).join(', ') + ' ,暗夜荆棘' ;
          if(anyeStr.includes(inputValue)) {
             navigate('/seriesAnye');
             return;
          }
           const liehuangStr: string = liehuangItems.map(item => item.name).join(', ') + ' ,猎荒派对' ;
          if(liehuangStr.includes(inputValue)) {
             navigate('/seriesLiehuang');
             return;
          }

           const relianStr: string = relianItems.map(item => item.name).join(', ') + ' ,热链玩家' ;
          if(relianStr.includes(inputValue)) {
             navigate('/seriesRelian');
             return;
          }

          const qianjiStr: string = qianjiItems.map(item => item.name).join(', ') + ' ,千机蝶变' ;
          if(qianjiStr.includes(inputValue)) {
             navigate('/seriesQianji');
             return;
          }

          navigate('/searchEmpty');
       }
    }

     const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.keyCode === 13) {
          handleSearchClick();
        }
     };

     const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
         if(e.target.value === '先锋潮流艺术珠宝') {
             setInputValue('');
         }
     }
    return (
      <>
        <div className="headerContainer">
           <div className="header__row">
             <a className="header__menu" onClick={handleShowMenu} >
                  <img  src={iconMenu} alt="" className="header__menu-img"/>
               </a>
               <Link to="/" className="header__logo">
                  <img  src={logoImg} alt="" className="header__logo-img"/>
               </Link>
               <div className="header__search">
                   <input 
                   value={inputValue}  
                   onChange={handleChange}
                   onKeyDown={handleKeyDown}
                   onFocus={handleFocus}
                    />
                   <a  className="header__search-btn"  onClick={handleSearchClick} ></a>
               </div>
           </div>
        </div>

         {
          isShowOver && 
           <div className="headerOverplay" onClick={handleHideMenu}>
           <div className="headerContainer__menuWrapper"  onClick={handleShowMenu}>
               <div className="headerContainer__row clearfix" >
                <div className="headerContainer__name">
                    abcd啊啵呲嘚
                </div>
                <div className="headerContainer__menuIcon" onClick={handleHideMenu}>
                     <img src={iconClose} alt="" className="headerContainer__menu-image" />
                </div>
               
            </div>
                <div  className="headerContainer__menu" onClick={handleHideMenu}>
                     <div className="headerContainer__menuCol" onClick={handleHideMenu} >
                        <Link to="/" className="headerContainer__menu-title" >
                             首页导航
                        </Link>
                        <div className="headerContainer__items">
                           <Link to="/" className="headerContainer__menu-itemLink" >
                              首页
                            </Link>
                            <Link to="/brand" className="headerContainer__menu-itemLink" >
                              品牌
                            </Link>
                            <Link to="/shop" className="headerContainer__menu-itemLink" >
                             门店
                            </Link>
                            <Link to="/contact" className="headerContainer__menu-itemLink" >
                             联系
                            </Link>
                        </div>
                    </div>
                     <div className="headerContainer__menuCol" onClick={handleHideMenu} >
                        <a className="headerContainer__menu-title" >
                           系列导航
                        </a>
                        <div className="headerContainer__items">
                            <Link to="/seriesYihuo" className="headerContainer__menu-itemLink" >
                             彝火生花
                            </Link>
                           <Link to="/seriesAnye" className="headerContainer__menu-itemLink" >
                             暗夜荆棘
                            </Link>
                             <Link to="/seriesLiehuang" className="headerContainer__menu-itemLink" >
                             猎荒派对
                            </Link>
                             <Link to="/seriesRelian" className="headerContainer__menu-itemLink" >
                             热链玩家
                            </Link>
                             <Link to="/seriesQianji" className="headerContainer__menu-itemLink" >
                             千机蝶变
                            </Link>
                        </div>
                    </div>
                </div>
                </div>
        </div>
        }
        </>
    )
}