import React from 'react';
import './Contact.less';

import infoImg from '../../assets/contact/info.png';
import wechatImg from "../../assets/contact/wechat.png";
import readBookImg from "../../assets/contact/read_book.png";
import weiboImg from "../../assets/contact/weibo.png";
import douyinImg from "../../assets/contact/douyin.png";
import joinImg from "../../assets/contact/join.png";

const ContactPage: React.FC = () => {

    return (
        <div className="contactPage">
            <div className="contactPage_info">
                <img  src={infoImg} alt="" className="contactPage_info-img"/>
            </div>
            <div className="contactPage__code">
                 <div className="contactPage__codeRow">
                     <div className="contactPage__codeCol">
                         <img  src={wechatImg} alt="" className="contactPage__codeCol-img"/>
                         <div className="contactPage__codeCol-name">微信公众号</div>
                          <div className="contactPage__codeCol-desc">abcd啊啵呲嘚</div>
                     </div>
                     <div className="contactPage__codeCol">
                         <img  src={readBookImg} alt="" className="contactPage__codeCol-img"/>
                         <div className="contactPage__codeCol-name">小红书</div>
                          <div className="contactPage__codeCol-desc">abcd啊啵呲嘚offical</div>
                     </div>
                     <div className="contactPage__codeCol">
                         <img  src={weiboImg} alt="" className="contactPage__codeCol-img"/>
                         <div className="contactPage__codeCol-name">微博</div>
                          <div className="contactPage__codeCol-desc">abcd啊啵呲嘚官方</div>
                     </div>
                     <div className="contactPage__codeCol">
                         <img  src={douyinImg} alt="" className="contactPage__codeCol-img"/>
                         <div className="contactPage__codeCol-name">抖音</div>
                          <div className="contactPage__codeCol-desc">abcd啊啵呲嘚</div>
                     </div>
                       <div className="contactPage__codeCol">
                         <img  src={joinImg} alt="" className="contactPage__codeCol-img"/>
                         <div className="contactPage__codeCol-name">合作</div>
                          <div className="contactPage__codeCol-desc">abcd啊啵呲嘚客服号</div>
                     </div>
                 </div>
            </div>
        </div>

    )
}

export default ContactPage;