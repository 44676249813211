import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./FourRow.less";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";


import fourRow1 from '../../../../assets/home/four_row_1.jpg';
import fourRow2 from '../../../../assets/home/four_row_2.jpg';
import fourRow3 from '../../../../assets/home/four_row_3.jpg';
import fourRow4 from '../../../../assets/home/four_row_4.png';

const boxVariant = {
    visible: { opacity: 1, transition: { duration: 1, delay: 0 } },
    hidden: { opacity: 0 }
};

const boxVariant1 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: .5 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant2 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1 } },
    hidden: { opacity: 0, translateY: 100 }
};

const boxVariant3 = {
    visible: { opacity: 1, translateY: 0, transition: { duration: 1, delay: 1.5 } },
    hidden: { opacity: 0, translateY: 100 }
}


const FourRow: React.FC = () => {

    const control = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);
    return (
        <div className="homePage__fourRow">
        <motion.div
                  ref={ref}
                  variants={boxVariant}
                  initial="hidden"
                  animate={control}
              >
      <Link to="/seriesYihuo"  className="homePage__fourCol">
          <div className="homePage__fourCol-imgWrap">
            <img  src={fourRow1} alt="" className="homePage__fourCol-img"/>
          </div>
          <div className="homePage__fourCol-textWrap">
             <div className="homePage__fourCol-title">彝火生花</div>
             <div className="homePage__fourCol-desc">流⾦烁银 参差⽣息</div>
          </div>
      </Link>
        </motion.div>
         <motion.div
                  ref={ref}
                  variants={boxVariant1}
                  initial="hidden"
                  animate={control}
              >
      <Link to="/seriesRelian" className="homePage__fourCol">
          <div className="homePage__fourCol-imgWrap">
            <img  src={fourRow2} alt="" className="homePage__fourCol-img"/>
          </div>
          <div className="homePage__fourCol-textWrap">
             <div className="homePage__fourCol-title">热链玩家</div>
             <div className="homePage__fourCol-desc">百搭灵感  ⽆链不欢</div>
          </div>
      </Link>
       </motion.div>
       <motion.div
                  ref={ref}
                  variants={boxVariant2}
                  initial="hidden"
                  animate={control}
              >
      <Link to="/seriesQianji"  className="homePage__fourCol">
          <div className="homePage__fourCol-imgWrap">
            <img  src={fourRow3} alt="" className="homePage__fourCol-img"/>
          </div>
          <div className="homePage__fourCol-textWrap">
             <div className="homePage__fourCol-title">千机蝶变</div>
             <div className="homePage__fourCol-desc">肆意洒脱 神秘自由</div>
          </div>
      </Link>
         </motion.div>
          <motion.div
                  ref={ref}
                  variants={boxVariant3}
                  initial="hidden"
                  animate={control}
              >
      <Link to="/seriesAnye"  className="homePage__fourCol">
          <div className="homePage__fourCol-imgWrap">
            <img  src={fourRow4} alt="" className="homePage__fourCol-img"/>
          </div>
          <div className="homePage__fourCol-textWrap">
             <div className="homePage__fourCol-title">暗夜荆棘</div>
             <div className="homePage__fourCol-desc">灵魂苏醒  肆意⽣长</div>
          </div>
      </Link>
        </motion.div>
    </div>
    );
};

export default FourRow;